import { Dialog, DialogTitle, DialogContent, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

const EditPopup = ({ open, onClose, url, meeting_id }) => {
  const [sessionurl, setSessionUrl] = useState(url);

  const handleEdit = async() => {
    const request = await axiosFetch({
      url: `/updateSessionRecordingURL?meeting_id=${meeting_id}`,
      method: "put",
      requestConfig: {
      sessionurl
      },
    })
    console.log(request);
    if(request.status ===200){
       toast.success(request.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
    }
    else{
      toast.success(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit URL</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          fullWidth
          value={sessionurl} 
          onChange={(e) => setSessionUrl(e.target.value)}  
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black",
              },
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
        />
        <div style={{ marginTop: "20px", textAlign: "right", display: "flex", gap: "1rem", justifyContent: "center" }}>
          <Button
            onClick={handleEdit} 
            variant="contained"
            sx={{
              backgroundColor: "#2a2a2a",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            Edit
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              backgroundColor: "#2a2a2a",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditPopup;
