import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useLiveSession = (handleClick, handleCopyLink, activeTab) => {
  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");

    const [month, day, year] = inputDate[0].split("/");
    const formattedDate = `${day.padStart(2, "0")}-${month}-${year}`;

    return [formattedDate, inputDate];
  };

  const zoomList = [
    { host: "primary", name: "Primary" },
    { host: "host_1", name: "Host 01" },
    { host: "host_2", name: "Host 02" },
    { host: "host_4", name: "Host 04" },
    { host: "host_5", name: "Host 05" },
    { host: "host_6", name: "Host 06" },
  ];

  let columns = [
    {
      field: "start_date",
      headerName: "Start Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return convertUtcToLocalTime(params.row?.start_time)[0];
      },
    },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return convertUtcToLocalTime(params.row?.start_time)[1][1];
      },
    },
    {
      field: "zoom_name",
      headerName: "Zoom Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const editZoom = zoomList.filter(
          (value) => value.host === params.row?.meeting?.host
        )[0];
        return editZoom?.name ? editZoom?.name : "-";
      },
    },

    {
      field: "program_name",
      headerName: "Program Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.program?.name;
        } else {
          return params.row?.meeting?.program?.name;
        }
      },
    },
    {
      field: "meting_id",
      headerName: "Session ID",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row?.id;
      },
    },
    {
      field: "session_name ",
      headerName: "Session Name ",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.session_name;
        } else {
          return params.row?.meeting?.session_name;
        }
      },
    },
    {
      field: "session_link",
      headerName: "Session Link",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className="session-mode">
            <span> {params.row?.join_url.slice(0, 15)}...</span>
            <span onClick={() => handleCopyLink(params.row?.join_url)}>
              <ContentCopyIcon
                style={{
                  fontSize: "20px",
                  color: "#6B6B6B",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return params.row?.status;
      },
    },
    {
      field: "format",
      headerName: "Format",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.format;
        } else {
          return params.row?.meeting?.format;
        }
      },
    },
    {
      field: "level",
      headerName: "Level",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.level;
        } else {
          return params.row?.meeting?.level;
        }
      },
    },
    {
      field: "time_zone",
      headerName: "Time zone",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.timezone;
        } else {
          return params.row?.meeting?.timezone;
        }
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return `${params.row?.updated_session_details?.duration} ${params.row?.updated_session_details?.duration_unit}`;
        } else if (params.row?.duration) {
          return `${params.row?.duration} ${params.row?.duration_unit}`;
        } else {
          return `${params.row?.meeting?.duration} ${params.row?.meeting?.duration_unit}`;
        }
      },
    },

    {
      field: "coach_name",
      headerName: "Coach Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.coach_name;
        } else {
          return params.row?.meeting?.coach_name;
        }
      },
    },
    {
      field: "visibility",
      headerName: "Visibility",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.visibility === true
            ? "ON"
            : "OFF";
        } else {
          return params.row?.meeting?.visibility === true ? "ON" : "OFF";
        }
      },
    },
    {
      field: "moderator_name",
      headerName: "Moderator Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.moderator_name;
        } else {
          return params.row?.meeting?.moderator_name;
        }
      },
    },
    {
      field: "created_date",
      headerName: "Created Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return convertUtcToLocalTime(
            params.row?.updated_session_details?.createdAt
          )[0];
        } else {
          return convertUtcToLocalTime(params.row?.meeting?.createdAt)[0];
        }
      },
    },
    {
      field: "created_by",
      headerName: "Created by",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.created_by;
        } else {
          return params.row?.meeting?.created_by;
        }
      },
    },
    {
      field: "updated_date",
      headerName: "Updated Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return convertUtcToLocalTime(
            params.row?.updated_session_details?.updatedAt
          )[0];
        } else {
          return convertUtcToLocalTime(params.row?.meeting?.updatedAt)[0];
        }
      },
    },
    {
      field: "updated_by",
      headerName: "Updated by",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.updated_by;
        } else {
          return params.row?.meeting?.updated_by;
        }
      },
    },
    {
      field: "who_should_avoid",
      headerName: "Who should avoid?",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        if (params.row?.updated_session_details) {
          return params.row?.updated_session_details?.who_should_avoid;
        } else {
          return params.row?.meeting?.who_should_avoid;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className="action-btn"
            onClick={(e) => handleClick(e, params.row)}
          >
            <MoreHorizIcon />
          </div>
        );
      },
    },
  ];

  if (activeTab === 0) {
    columns = columns.filter(
      (column) =>
        column.headerName !== "Status" && column.headerName !== "Visibility"
    );
  } else {
    columns = columns.filter((column) => column.headerName !== "Session Link");
  }

  return columns;
};

export default useLiveSession;
