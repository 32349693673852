import React, { useEffect, useReducer, useState } from "react";

import { Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import HideSource from "@mui/icons-material/HideSource";
import Undo from "@mui/icons-material/Undo";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

import DeleteModal from "../../NewClientLeadTable/modals/DeleteModal";

const initialValue = {
  isDelete: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "closeModel": {
      return {
        ...state,
        isDelete: action.payload,
      };
    }

    default:
      throw new Error("Unknown action");
  }
};

const ActionMenu = ({ anchorEl, onClose, currentRow, getProgramList }) => {
  const history = useHistory();

  const [{ isDelete }, dispatch] = useReducer(reducer, initialValue);

  const handleClick = (item) => {
    if (item === "view") {
      history.push("/programs/createNewProgramNew", { currentRow, view: true });
    } else {
      history.push("/programs/createNewProgramNew", { currentRow, edit: true });
    }
  };

  const handleDeleteModal = () => {
    onClose();
    dispatch({ type: "closeModel", payload: true });
  };


  const handleHideProgram = async () => {
    let data = JSON.parse(localStorage.getItem("ProgramActiveRow"));

    console.log(data.id, 'data')

    let request = await axiosFetch({
      url: `/hideProducts/${data.id}`,
      method: "put",
    });

    if (request.status === 200) {
      toast.success("Program Hide successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "closeModel", payload: false });
      getProgramList();
    }

  };

  const handleUnHideProgram = async () => {

    let data = JSON.parse(localStorage.getItem("ProgramActiveRow"));

    console.log(data.id, 'data')

    let request = await axiosFetch({
      url: `/unhideProducts/${data.id}`,
      method: "put",
    });

    if (request.status === 200) {
      toast.success("Program UnHide successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "closeModel", payload: false });
      getProgramList();
    }

  };

  const handleDelete = async () => {
    let deleteRow = JSON.parse(localStorage.getItem("ProgramActiveRow"));

    let request = await axiosFetch({
      url: `/deleteProduct/${deleteRow?.id}`,
      method: "delete",
    });

    if (request.status === 200) {
      toast.success("Program deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "closeModel", payload: false });
      getProgramList();
    }
  };

  const handleActiveInactive = async (isActive) => {
    let activeRow = JSON.parse(localStorage.getItem("ProgramActiveRow"));

    let request = await axiosFetch({
      url: `/changeProgramStatus/${activeRow.id}`,
      method: "put",
      requestConfig: {
        is_active: isActive,
      },
    });

    if (request.status === 200) {
      toast.success(
        `Program ${isActive ? "Active" : "InActive"} successfully`,
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      onClose();
      getProgramList();
    }
  };

  useEffect(() => {
    if (currentRow) {
      localStorage.setItem("ProgramActiveRow", JSON.stringify(currentRow));
    }
  }, [currentRow]);

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          top: "-100px",
          left: "-100px",
        }}
      >
        {/* onClick={(e) => setOpen(e.target)} */}
        <MenuItem onClick={() => handleClick("view")}>
          <span className="mr-5">
            <VisibilityIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">View</span>
        </MenuItem>
        <MenuItem onClick={handleClick}>
          <span className="mr-5">
            <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Edit</span>
        </MenuItem>
        <MenuItem onClick={() => handleActiveInactive(true)}>
          <span className="mr-5">
            <CheckIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Active</span>
        </MenuItem>
        <MenuItem onClick={() => handleActiveInactive(false)}>
          <span className="mr-5">
            <CloseIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Inactive</span>
        </MenuItem>
        <MenuItem onClick={handleDeleteModal}>
          <span className="mr-5">
            <DeleteIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Delete</span>
        </MenuItem>

        {

          currentRow && !currentRow.isHide_flag &&
          <MenuItem onClick={handleHideProgram}>
            <span className="mr-5">
              <HideSource style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">Hide</span>
          </MenuItem>
        }

        {
          currentRow && currentRow.isHide_flag &&
          <MenuItem onClick={handleUnHideProgram}>
            <span className="mr-5">
              <Undo style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">UnHide</span>
          </MenuItem>
        }

      </Menu>
      {isDelete && (
        <DeleteModal dispatch={dispatch} handleDelete={handleDelete} />
      )}
    </div>
  );
};

export default ActionMenu;
