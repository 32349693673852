import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "../../../../components/Modal/Modal";

import { Card, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styles from "./FreezeProgram.module.css";
import Button from "../../../../components/CustomButton/Button";
import { MUIDateConverter } from "../../../../hooks/helperFunction/dateConverter";
import dayjs from "dayjs";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import moment from "moment";

const initialState = {
  programName: "",
  fromDate: null,
  toDate: null,
  endDate: null,
  addDays: "",
  remarks: "",
};

export const FreezeProgram = ({ isOpen, onClose, client, reLoadList }) => {

  
  let { product_details, end_date, start_date
  } = client;

  const [endDatas, setEndDate] = useState(end_date)
  const [disableBtn,setDisableBtn]=useState(false)
  const form = useForm({
    defaultValues: initialState,
  });
  const { handleSubmit, control, reset, setValue, watch } = form;
  const watchedToDate = watch("toDate");

  const handleDays = (addDays) => {
    // const endDateObj = new Date(watchedToDate);
   
    const endDateObj = new Date(client?.end_date);
    endDateObj.setDate(endDateObj.getDate() + Number(addDays));
    const newDate = endDateObj.toISOString().split("T")[0];
    setValue("endDate", dayjs(newDate));
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (formData) => {
    const updatedFormData = {
      ...formData,
      endDate: formData.endDate && MUIDateConverter(formData.endDate)[0],
    };

    delete updatedFormData.programName;

    try {

      

      const now = moment();

      // if (moment(updatedFormData.fromDate).isBefore(now, 'day')) {
      //   toast.warning('No Less than current date', {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      //   return false
      // }

      if(disableBtn){
        return false
      }

      setDisableBtn(true)
      if (moment(updatedFormData.toDate).isSameOrBefore(moment(updatedFormData.fromDate), 'day')) {
        toast.warning('End Date must not less or equal then Start Date', {
          position: toast.POSITION.TOP_CENTER,
        });
        setDisableBtn(false)
        return false
      }
      const checkForExistsFreeProgram = await axiosFetch({
        url: `/checkForExistsFreeProgram/${client?.id}`,
        method: "post",
        requestConfig: updatedFormData,
      });

      if (checkForExistsFreeProgram.data.isAllowedProgram) {

        toast.warning(checkForExistsFreeProgram.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setDisableBtn(false)
        return false
      }

      const response = await axiosFetch({
        url: `/freezeProgram/${client?.id}`,
        method: "post",
        requestConfig: updatedFormData,
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        onClose();
        reLoadList();
        setDisableBtn(false)
      }else{
        setDisableBtn(false)
      }
    
    } catch {}
  };

  useEffect(() => {
    if (product_details) {
      const getData = async () => {
        // setEndDate(moment(checkForExistsFreeProgram.data.data.).add(1,'day'))
        reset({
          ...initialState,
          programName: product_details.name,
        });
      }
      getData()
    }
  }, [product_details, reset]);

  const inputFormat = "DD-MM-YYYY"
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Card elevation={0} sx={{ width: "600px", padding: "10px" }}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#2A2A2A",
                  fontSize: "24px",
                }}
              >
                Pause Program
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                Program Name
              </Typography>
              <Controller
                name="programName"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                Start Date
              </Typography>
              <Controller
                name="fromDate"
                control={control}
                rules={{ required: "required" }}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DatePicker
                      format={inputFormat}
                      sx={{ width: "100%" }}
                      {...field}
                      onChange={(event) => {
                        field.onChange(MUIDateConverter(event.$d)[0]);
                      }}
                      value={dayjs(field.value)}
                      minDate={dayjs(start_date).isBefore(dayjs()) 
                        ? dayjs().subtract(Math.min(dayjs().diff(dayjs(start_date), 'days'), 5), 'days') 
                        : dayjs(start_date)}
                      maxDate={endDatas?undefined  : dayjs(end_date)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                End Date
              </Typography>
              <Controller
                name="toDate"
                control={control}
                rules={{ required: "required" }}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DatePicker
                      format={inputFormat}
                      sx={{ width: "100%" }}
                      {...field}
                      onChange={(event) => {
                        field.onChange(MUIDateConverter(event.$d)[0]);
                      }}
                      value={field.value && dayjs(field.value)}
                      minDate={dayjs(start_date)}
                      maxDate={dayjs(end_date)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            {/* <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                Add Days
              </Typography>
              <Controller
                name="addDays"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      handleDays(e.target.value);
                    }}
                    value={field.value}
                    type="number"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                Extension Tenure End Date
              </Typography>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DatePicker
                      sx={{ width: "100%" }}
                      {...field}
                      // onChange={(event) => {
                      //   field.onChange(MUIDateConverter(event.$d)[0]);
                      // }}
                      // value={field.value && dayjs(field.value)}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid> */}
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                Remarks
              </Typography>
              <Controller
                name="remarks"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                outline
                type={"button"}
                className={"session-btn-confirm"}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button disabled={disableBtn} inline type={"submit"} className={"session-btn-confirm"}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Modal>
  );
};
