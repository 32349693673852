import axios from "axios";
import {
  ATLAS_URI
} from "../../src/Constants";

var configHeader = {};

const activeRequests = [];
const toImplementTable = ['leadList']
export const axiosFetch = async (configObj) => {
  let data;

  const cancelToken = axios.CancelToken.source();

  if (toImplementTable.includes(configObj.requestId)) {
    activeRequests.push({
      cancel: cancelToken.cancel
    });

    if (activeRequests.length > 0) {
      activeRequests.forEach((request, index) => {
        if (index != activeRequests.length - 1) {
          request.cancel()
        }
      })
    }

    data = {
      baseURL: ATLAS_URI,
      headers: configHeader,
      cancelToken: cancelToken.token
    }
  } else {
    data = {
      baseURL: ATLAS_URI,
      headers: configHeader,
    }
  }


  var userDetails =
    localStorage.getItem("operator") !== null ?
    localStorage.getItem("operator") :
    null;

  var token =
    userDetails !== null && JSON.parse(userDetails).token !== null ?
    JSON.parse(userDetails).token :
    "";
  if (token) {
    configHeader = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    configHeader = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
    };
  }

  const ConfigAxios = axios.create(data);

  const {
    method,
    url,
    requestConfig = {}
  } = configObj;
  try {
    const res = await ConfigAxios[method.toLowerCase()](url, requestConfig);
    if (toImplementTable.includes(configObj.requestId)) {
      activeRequests.splice(0, activeRequests.length);
    }
    return res;
  } catch (err) {

    return err.response;
  }
};