import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import DataViewer from "../../../components/DataViewer/DataViewer";
import ControlledTextField from "../../../components/ControlledTextField";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../../components/ControlledAutocomplete";
import ControlledRadioGroup from "../../../components/ControlledRadioGroup";
import ControlledTimepicker from "../../../components/ControlledTimepicker";
import ControllerDatetimepicker from "../../../components/ControlledDatetimepicker";
import FormRenderer from "../../../components/FormRenderer/FormRenderer";
import formdata from "./diet_preference";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { date } from "yup";
import { toast } from "react-toastify";

const MedicalAssesmentForm = ({ displaydata, update }) => {
  const [isedit, setIsedit] = useState(false);
  const [masters, setMasters] = useState({});
  const [isothermedication, setIsothermedication] = useState(false);
  const [infoview, setInfoview] = useState({});

  const defaultValues = {
    createDate: new Date(),
  };
  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors, isValid },
  } = methods;
  let is_tablet_recommented = watch("tablets_recommended_if_yes");
  useEffect(() => {
    if (is_tablet_recommented == "Yes") {
      setIsothermedication(true);
    } else {
      setIsothermedication(false);
    }
  }, [is_tablet_recommented]);
  const handleClick = (event) => {
    setIsedit(!isedit);
  };
  useEffect(() => {
    axiosFetch({
      method: "get",
      url: `/getMedicalConditionMaster`,
    })
      .then((response) => {
        setMasters({
          ...masters,
          medicalCondition: [...response.data.body.medical_condition],
          diseaseMaster: [...response.data.body.disease_master],
          bodyAches: [...response.data.body.body_aches],
        });
      })
      .catch((err) => console.error(err));
  }, []);
  const handleCancel = (event) => {
    setIsedit(false);
  };

  useEffect(() => {
    if (displaydata) {
      let dietInfo = displaydata?.medicalAssessment;

      setValue(
        "current_medical_condition",
        dietInfo?.current_medical_condition
      );

      // setValue(
      //   "tablets_recommended_if_yes",
      //   dietInfo?.tablets_recommended_if_yes == 1 ? "Yes" : "No"
      // );
      setValue(
        "tablets_recommended_if_yes",
        dietInfo?.tablets_recommended ? "Yes" : "No"
      );

      setValue("tablets_recommended", dietInfo?.tablets_recommended);
      setValue("other_medical_condition", dietInfo?.other_medical_condition);
      setValue("lead_body_pain", dietInfo?.lead_body_pain);
      setValue("is_healthcheckup", dietInfo?.is_healthcheckup);
      setValue("other_deficiency", dietInfo?.other_deficiency);
      setValue("other_bodyache", dietInfo?.other_bodyache);
      setValue("any_deficiency", dietInfo?.any_deficiency);
      setValue("is_surgery", dietInfo?.is_surgery);
      setInfoview({
        "Current Medical Condition": dietInfo?.current_medical_condition && dietInfo?.current_medical_condition.join(),
        "Other Medical Condition": dietInfo?.other_medical_condition,
        "Are you taking any medications":
          dietInfo?.tablets_recommended_if_yes == 1 ? "Yes" : "No",

        "Other Medications": dietInfo?.tablets_recommended,

        "Select your key reasons for fitness": dietInfo?.any_deficiency,
        "Other Health issues": dietInfo?.other_deficiency,
        "Body Aches": dietInfo?.lead_body_pain,
        "Other Body Aches": dietInfo?.other_bodyache,

        "Any recent health checkups":
          dietInfo?.is_healthcheckup == 1 ? "Yes" : "No",
        "Any history of surgery": dietInfo?.is_surgery == 1 ? "Yes" : "No",
      });

      //  setValue("mealtimings",dietInfo?.mealtimings)

      // setValue("gender",basicinfo?.gender)
      // setValue("dob",basicinfo?.dob)
    }
  }, [displaydata]);

  const handlemedicalassestment = async (data) => {
    const isNotValid = () => {
      toast.error("Please fill all the Yes mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    };

    const { tablets_recommended, tablets_recommended_if_yes } = data;

    if (tablets_recommended_if_yes === "Yes" && !tablets_recommended) {
      isNotValid();
      return;
    }

    setIsedit(false);

    update({
      ...data,
      formtype: 4,
    });
  };

  const current_medical_condition_arr = watch("current_medical_condition");
  useEffect(() => {
    if (current_medical_condition_arr?.indexOf("Others") == -1) {
      setValue("other_medical_condition", "");
    } else {
    }
  }, [current_medical_condition_arr]);

  const any_deficiency = watch("any_deficiency");
  useEffect(() => {
    if (any_deficiency?.indexOf("Others") == -1) {
      setValue("other_deficiency", "");
    } else {
    }
  }, [any_deficiency]);

  const lead_body_pain = watch("lead_body_pain");
  useEffect(() => {
    if (lead_body_pain?.indexOf("Others") == -1) {
      setValue("other_bodyache", "");
    } else {
    }
  }, [lead_body_pain]);
  const tablets_recommended_if_yes = watch("tablets_recommended_if_yes");
  useEffect(() => {
    if (tablets_recommended_if_yes?.toLowerCase() == "no") {
      setValue("tablets_recommended", "");
    } else {
    }
  }, [tablets_recommended_if_yes]);

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit((data) => {
          handlemedicalassestment(data);
        })}
      >
        {masters?.medicalCondition && (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  multiple={true}
                  label={"Any Medical Condition"}
                  name={"current_medical_condition"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={
                    displaydata?.medicalAssessment?.current_medical_condition ??
                    []
                  }
                  options={
                    masters?.medicalCondition ? masters?.medicalCondition : []
                  }
                />
              ) : (
                <Box sx={{ width: "300px" }}>
                  <Typography variant="caption">
                    Any Medical Condition 
                  </Typography>
                  <Box>
                    {displaydata?.medicalAssessment
                      ?.current_medical_condition && (
                      <>
                        {displaydata?.medicalAssessment?.current_medical_condition?.map(
                          (itm) => (
                            <Chip label={itm} sx={{ margin: "2px" }} />
                          )
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
            {/* <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={"Other Medical Condition"}
                  name={"other_medical_condition"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  isDisableColumn={
                    getValues("current_medical_condition")?.indexOf("Others") ==
                    -1
                  }
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">
                    Other Medical Condition
                  </Typography>
                  <Typography variant="h6">
                    {displaydata?.medicalAssessment?.other_medical_condition}
                  </Typography>
                </Stack>
              )}
            </Grid> */}
            <Grid container item xs={3}>
              {isedit ? (
                <ControlledRadioGroup
                  label={"Are you taking any medications?"}
                  name={"tablets_recommended_if_yes"}
                  control={control}
                  row={true}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={[
                    {
                      value: "Yes",
                      label: "Yes",
                    },
                    {
                      value: "No",
                      label: "No",
                    },
                  ]}
                />
              ) : (
                <Box>
                  <Typography variant="caption">
                    Are you taking any medications?
                  </Typography>
                  <Typography variant="h6">
                    {/* {displaydata?.medicalAssessment
                      ?.tablets_recommended_if_yes == 1 */}{" "}
                    {displaydata?.medicalAssessment?.tablets_recommended
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <>
                  <ControlledTextField
                    label={""}
                    name={"tablets_recommended"}
                    control={control}
                    rules={{}}
                    fullWidth={true}
                    variant="outlined"
                    isDisableColumn={
                      getValues("tablets_recommended_if_yes")?.toLowerCase() ==
                      "no"
                    }
                  />
                </>
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Other Medications</Typography>
                  <Typography variant="h6">
                    {displaydata?.medicalAssessment?.tablets_recommended ??
                      "--"}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  multiple={true}
                  label={"Select your key reasons for fitness"}
                  name={"any_deficiency"}
                  defaultValue={
                    displaydata?.medicalAssessment?.any_deficiency ?? []
                  }
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={masters?.diseaseMaster ? masters?.diseaseMaster : []}
                />
              ) : (
                <Box sx={{ width: "300px" }}>
                  <Typography variant="caption">
                    Select your key reasons for fitness
                  </Typography>
                  <Box>
                    {displaydata?.medicalAssessment?.any_deficiency && (
                      <>
                        {displaydata?.medicalAssessment?.any_deficiency?.map(
                          (itm) => (
                            <Chip label={itm} sx={{ margin: "2px" }} />
                          )
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            {/* <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={""}
                  name={"other_deficiency"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  isDisableColumn={
                    getValues("any_deficiency")?.indexOf("Others") == -1
                  }
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Other Health issues</Typography>
                  <Typography variant="h6">
                    {displaydata?.medicalAssessment?.other_deficiency ?? "--"}
                  </Typography>
                </Stack>
              )}
            </Grid> */}
            <Grid item xs={3}>
              {isedit ? (
                <ControlledAutocomplete
                  label={"Body Aches"}
                  multiple={true}
                  name={"lead_body_pain"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={
                    displaydata?.medicalAssessment?.lead_body_pain ?? []
                  }
                  options={masters?.bodyAches ? masters?.bodyAches : []}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Body Aches</Typography>
                  {displaydata?.medicalAssessment?.lead_body_pain && (
                    <Stack direction="row" spacing={1}>
                      {displaydata?.medicalAssessment?.lead_body_pain?.map(
                        (itm) => (
                          <Chip label={itm} />
                        )
                      )}{" "}
                    </Stack>
                  )}{" "}
                </Stack>
              )}
            </Grid>
            <Grid item xs={3}>
              {isedit ? (
                <ControlledTextField
                  label={""}
                  name={"other_bodyache"}
                  control={control}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  isDisableColumn={
                    getValues("lead_body_pain")?.indexOf("Others") == -1
                  }
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">Other Body Aches</Typography>
                  <Typography variant="h6">
                    {displaydata?.medicalAssessment?.other_bodyache ?? "--"}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid container item xs={3} alignItems={"center"}>
              {isedit ? (
                <ControlledRadioGroup
                  label={"Any recent health checkup?"}
                  name={"is_healthcheckup"}
                  control={control}
                  row={true}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={[
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">
                    Any recent health checkup
                  </Typography>
                  <Typography variant="h6">
                    {displaydata?.medicalAssessment?.is_healthcheckup
                      ? "YES"
                      : "NO"}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid container item xs={3} alignItems={"center"}>
              {isedit ? (
                <ControlledRadioGroup
                  label={"Any history of surgery?"}
                  name={"is_surgery"}
                  control={control}
                  row={true}
                  rules={{}}
                  fullWidth={true}
                  variant="outlined"
                  options={[
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]}
                />
              ) : (
                <Stack spacing={1}>
                  <Typography variant="caption">
                    Any history of surgery?
                  </Typography>
                  <Typography variant="h6">
                    {displaydata?.medicalAssessment?.is_surgery ? "YES" : "NO"}
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {isedit && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Button variant="outlined" onClick={handleCancel}>
                {" "}
                Cancel{" "}
              </Button>
              <Button variant="contained" type="submit">
                {" "}
                Save{" "}
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {!isedit && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Button variant="contained" onClick={handleClick}>
              {" "}
              Edit{" "}
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default MedicalAssesmentForm;

//btn-disabled
