import React, { useEffect, useState } from 'react';
import { Box, Card, Modal, Tabs, Tab, Typography, Table, TableBody, TableRow, TableCell, IconButton, TableHead } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { axiosFetch } from '../../../../Utlis/AxiosFetch';
import moment from 'moment';

function HistoryPopup({ isOpen, onClose, client }) {
  console.log(client);
  const [selectedTab, setSelectedTab] = useState(0); 
  const [historyData, setHistoryData] = useState([]);
  const history = useHistory();

  const handleClose = () => {
    if (onClose) {
      onClose(); 
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseButton = () => {
    
    history.push({
      pathname: "/Clients/clientDetail/" + client.lead_id,
    });

   
    handleClose();
  };


  const fetchHistory = async (status) => {
    
    try {
      const response = await axiosFetch({
        url: `/programHistory/${client?.id}/${status}`,
        method: "get",
        // requestConfig: updatedFormData,
      });
     
      if (response.data) {
        console.log(response.data.body , 'response.data.body')
        setHistoryData(response.data.body);  
      }
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      
      const status = selectedTab === 0 ? 'freeze' :selectedTab === 1 ? 'extension':'stop' ;
      setHistoryData([])
      fetchHistory(status);
      console.log(status);
    }
  }, [isOpen,selectedTab,client.id]);


  console.log(historyData);
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Card
        elevation={0}
        sx={{
          width: "900px",
          padding: "10px",
          margin: "auto", 
          marginTop: "10%", 
          position: "relative",
          zIndex: 1301, 
        }}
      >
       
        <IconButton
          onClick={handleCloseButton} 
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "gray", 
            zIndex: 1302, 
          }}
        >
          <CloseIcon />
        </IconButton>
        
       
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'black', 
              }
            }}
            sx={{
              '& .MuiTab-root': {
                color: 'gray', 
              },
              '& .MuiTab-root.Mui-selected': {
                color: 'black', 
                fontWeight: 'bold', 
              },
            }}
        >
          <Tab label="Paused" />
          <Tab label="Extension" />
          <Tab label="Stopped" />
        </Tabs>

        {/* Tab Content */}
        <Box sx={{ marginTop: "20px" }}>
          {selectedTab === 0 && (
          <>
             <TableHead>
  <TableRow>
    <TableCell><strong>S.No</strong></TableCell>
    <TableCell><strong>Program Name</strong></TableCell>
    <TableCell><strong>Paused Date</strong></TableCell>
    <TableCell><strong>Paused till</strong></TableCell>
    <TableCell><strong>Paused Days</strong></TableCell>
    {/* <TableCell><strong>Added Days</strong></TableCell> */}
    <TableCell><strong>Completed</strong></TableCell>
    <TableCell><strong>Added By</strong></TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {historyData.length > 0 ? (
    historyData.map((item, index) => (
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{client.product_details?.name || 'N/A'}</TableCell>
        <TableCell>{item.fromDate ? moment(item.fromDate).format('DD/MM/YYYY') : 'N/A'}</TableCell>
        <TableCell>{item.toDate ? moment(item.toDate).format('DD/MM/YYYY') : 'N/A'}</TableCell>
        <TableCell>
          {item.fromDate && item.toDate 
            ? moment(item.toDate).diff(moment(item.fromDate), 'days') + ' days'
            : 'N/A'}
        </TableCell>

        {/* <TableCell>
          {item.addDays != null ?item.addDays:'0' }
        </TableCell> */}
        <TableCell>
          {item.isCompleted?'Completed' : 'Pending'}
        </TableCell>
        
        <TableCell>{item?.addedBy?.Username}</TableCell>

      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={6} align="center">No history available</TableCell>
    </TableRow>
  )}
</TableBody>

          </>

          )}
          {selectedTab === 1 && (
            <>
              
              <Table sx={{ width: '100%', borderCollapse: 'collapse' }}>
                <TableHead>
                  <TableRow>
                  <TableCell><strong>S.No</strong></TableCell>
                    <TableCell><strong>Program Name</strong></TableCell>
                    <TableCell><strong>Previous End Date</strong></TableCell>
                    <TableCell><strong>Extended End Date</strong></TableCell>
                    <TableCell><strong>Extended Days</strong></TableCell>
                    <TableCell><strong>Added By</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {historyData.length > 0 ? (
                    historyData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{client.product_details.name}</TableCell>
                        <TableCell>{item.previous_end_date ? moment(item.previous_end_date).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                        <TableCell>{item.extended_end_date ? moment(item.extended_end_date).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                        <TableCell>
                            {
                              item.previous_end_date && item.extended_end_date 
                                ? moment(item.extended_end_date).diff(moment(item.previous_end_date), 'days') + ' days'
                                : 'N/A'
                            }
                        </TableCell>
                        <TableCell>{item?.addedByName || 'N/A'}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">No history available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </>
          )}
           {selectedTab === 2 && (
            <>
              <Table sx={{ width: '100%', borderCollapse: 'collapse' }}>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>S.No</strong></TableCell>
                    <TableCell><strong>Program Name</strong></TableCell>
                    <TableCell><strong>Stoped Date</strong></TableCell>
                    <TableCell><strong>Resumed Date</strong></TableCell>
                    <TableCell><strong>Completed</strong></TableCell>
                    <TableCell><strong>Added By</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyData.length > 0 ? (
                    historyData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{client.product_details.name}</TableCell>
                        <TableCell>{item.fromDate ? moment(item.fromDate).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                        <TableCell>{item.toDate ? moment(item.toDate).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                        {/* <TableCell>
                          {
                            item.previous_end_date && item.extended_end_date
                              ? moment(item.extended_end_date).diff(moment(item.previous_end_date), 'days') + ' days'
                              : 'N/A'
                          }
                        </TableCell> */}
                        <TableCell>{item.isCompleted?'Completed':'Pending'}</TableCell>
                        <TableCell>{item?.addedBy?.Username || 'N/A'}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">No history available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export default HistoryPopup;
