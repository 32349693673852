import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useEffect, useReducer, useState } from "react";

import useListClient from "../../../hooks/tableColumns/useClientList";
import ActionMenu from "../modals/ActionMenu";
import useListLead from "../../../hooks/tableColumns/useLeadList";

import ClientTable from "./ClientTable";
import LeadTable from "./LeadTable";

import CallPopup from "../../Call/CallPopup";
import TeleCall from "../../Call/TeleCall";
import Popmodel from "../../Leads/Popupmodel";
import TaskModal from "../../../components/TaskModal/TaskModal";
import Servetel from "../../Call/Servetel";

import {
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom";

const CLIENT_LC_STORAGE_DEFAULT = {
  filter: {
    filterModel: {
      items: [],
      logicOperator: "and",
      quickFilterLogicOperator: "and",
      quickFilterValues: [],
    },
  },
  pagination: {
    paginationModel: {
      page: 0,
      pageSize: 50,
    },
  },
  preferencePanel: {
    open: true,
    openedPanelValue: "filters",
  },
  sorting: {
    sortModel: [],
  },
};

const pinList = [
  "id",
  "name",
  "Name",
  "Email",
  "Phone",
  "contact_number",
  "email_id",
  "action",
];

const clientPinList = [
  "id",
  "name",
  "Name",
  "Email",
  "Phone",
  "contact_number",
  "email_id",
  "programStatus",
  "action",
];

const initialState = {
  role: JSON.parse(window.localStorage.getItem("loginDetails"))?.id,
  userId: JSON.parse(window.localStorage.getItem("operator"))?.id,
  rows: [],
  currentVisibleColumns: "",
  count: 0,
  anchorEl: null,
  currentRow: {},
  showCallModal: false,
  showTaskModal: false,
  callType: "",
  top: "-100px",
  left: "-200px",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "current": {
      return {
        ...state,
        currentVisibleColumns: action.payload,
      };
    }
    case "getRequest": {
      return {
        ...state,
        rows: action?.payload?.rows,
        count: action?.payload?.count,
      };
    }

    case "showModal": {
      return {
        ...state,
        showCallModal: action.payload?.callModal,
        showTaskModal: action.payload?.taskModal,
      };
    }

    case "currentAction": {
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        currentRow: action.payload.currentRow,
      };
    }

    case "servetel": {
      return {
        ...state,
        callType: action.payload,
      };
    }

    default: {
      throw new Error("Unkown Action");
    }
  }
};

const Table = ({
  onDispatch,
  reloadTable,
  globalSearchedValue,
  validitySearch,
}) => {
  const apiRef = useGridApiRef();
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname === "/Leads/listLeads" ? "lead" : "client";

  let { visibileClientColumns, clientColumnsList } = useListClient(handleClick);
  let { visibileLeadColumns, leadColumnsList, medicalCondition } =
    useListLead(handleClick);
  const [
    {
      role,
      userId,
      count,
      rows,
      anchorEl,
      currentRow,
      currentVisibleColumns,
      showCallModal,
      showTaskModal,
      top,
      left,
      callType,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  let leadId = pathName === "lead" ? currentRow?.id : currentRow?.Lead;

  // Get Client And Lead data

  const getTableData = async (filterObject = null,cleanupProcess = false) => {
    let fullstate = null;
    let url;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (filterObject) {
      fullstate = filterObject;
    } else {
      fullstate = apiRef.current.exportState();
      localStorage.setItem("CLIENT_LC_STORAGE", JSON.stringify(fullstate));
    }

    if (role === 1 || role === 28 || role === 30 || role === 13) {
      url = "getClients";
    } else {
      url = "getClientsByAssignedToUser";
    }

    url = pathName === "lead" ? "getLeads" : url;

    let api = `${url}?page=${
      fullstate?.pagination?.paginationModel.page + 1
    }&pageSize=${
      fullstate?.pagination?.paginationModel.pageSize
    }&timezone=${timezone}`;

    const value = localStorage.getItem("ClientLeadGlobalSearch");
    let searchValue;

    if (value) {
      searchValue = value;
      localStorage.removeItem("testSearch");
    } else {
      searchValue = globalSearchedValue;
    }

    const allValiditySearch = validitySearch
      .map((item) => `&validity=${item}`)
      .join("&");

    if (searchValue && validitySearch.length > 0) {
      api += `&search=${searchValue}${allValiditySearch}`;
    } else if (validitySearch.length === 0) {
      api += `&search=${searchValue}`;
    } else if (validitySearch.length > 0) {
      api += allValiditySearch;
    }

    console.log(fullstate?.filter?.filterModel , 'fullstate?.filter?.filterModel')

    let isContinew = true
    if(fullstate?.filter?.filterModel.items.length>0){
      isContinew = false
      function hasValueKey() {
        if (fullstate?.filter?.filterModel?.items) {
          return fullstate.filter.filterModel.items.every(item => item.hasOwnProperty('value'));
        }
        return false;
      }
     const isAllowed = hasValueKey()
     isContinew=isAllowed
    }
    if(!isContinew){
      return false
    }
    

    let options = {
      url: api,
      method: "post",
      requestConfig: fullstate?.filter?.filterModel,
    }
    
    if (cleanupProcess) {
      options = {
        ...options,
        requestId:'leadList'
      }      
    }

    const request = await axiosFetch(options);

    dispatch({
      type: "getRequest",
      payload:
        pathName === "lead"
          ? { rows: request?.data?.leads, count: request?.data?.count }
          : request?.data,
    });
  };

  // Resotre Table

  const restore = () => {
    let CLIENT_LC_STORAGE = localStorage.getItem("CLIENT_LC_STORAGE") || null;

    const fullFilterObject = JSON.parse(CLIENT_LC_STORAGE);

    apiRef.current.restoreState(fullFilterObject);
    getTableData(fullFilterObject);
  };

  // Next And Prev Page Function handler

  function handleDataGridChange() {
    let isRestore = localStorage.getItem("RESTORE_CLIENT_LISTING_SCREEN");
    let isGlobalRestore = localStorage.getItem("testSearch");

    if (isRestore || isGlobalRestore) {
      localStorage.removeItem("RESTORE_CLIENT_LISTING_SCREEN");
      return;
    }

    // if (value) {
    //   localStorage.setItem("testSearch", true);
    // }

    const fullstate = apiRef.current.exportState();
    localStorage.setItem("CLIENT_LC_STORAGE", JSON.stringify(fullstate));

    getTableData(null,true);
  }

  // action button Click handler

  function handleClick(e, params) {
    dispatch({
      type: "currentAction",
      payload: { anchorEl: e.target, currentRow: params.row },
    });

    localStorage.setItem("currentRow", JSON.stringify(params.row));
  }

  // Handle Row selection

  const handleChangeRowSelection = (arr, event) => {
    const deleteList = event.api.getSelectedRows();

    onDispatch({
      type: "deleteList",
      payload: Array.from(deleteList.values()).map((entry) => {
        if (pathName === "lead") {
          return entry.id;
        }

        return entry.Lead;
      }),
    });

    if (arr.length > 0) {
      onDispatch({
        type: "selectedRow",
        payload: arr,
      });
    } else {
      onDispatch({
        type: "selectedRow",
        payload: null,
      });
    }
  };

  // help to save column list

  const handleChangeColumns = (changeColumns) => {
    onDispatch({ type: "saveColumList", payload: changeColumns });
    dispatch({ type: "current", payload: changeColumns });
  };

  // edit button Click handler

  const handleEdit = () => {
    dispatch({ type: "currentAction", payload: { anchorEl: null } });
    let pushName = pathName === "lead" ? "/lead/" : "/Clients/clientDetail/";

    let backToTable =
      pathName === "lead" ? "/Leads/listLeads" : "/Clients/listClients";

    localStorage.setItem("backToTable", backToTable);

    history.push({
      pathname: pushName + leadId,
      state: currentRow,
    });
  };

  // navigate to whatsapp

  const handleWhatsapp = () => {
    dispatch({ type: "currentAction", payload: { anchorEl: null } });
    let PhoneNumber =
      pathName === "lead" ? currentRow.contact_number : currentRow.Phone;

    const whatsappLink = `https://wa.me/${PhoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  // handle call

  const handleCall = () => {
    dispatch({
      type: "currentAction",
      payload: { anchorEl: null, currentRow: currentRow },
    });
    let backToTable =
      pathName === "lead" ? "/Leads/listLeads" : "/Clients/listClients";

    localStorage.setItem("backToTable", backToTable);

    dispatch({ type: "showModal", payload: { callModal: true } });
  };

  // handle Add Task

  const handleTask = () => {
    dispatch({
      type: "currentAction",
      payload: { anchorEl: null, currentRow: currentRow },
    });
    dispatch({ type: "showModal", payload: { taskModal: true } });
  };

  // handle Servetel modal

  const handleServetel = (value) => {
    dispatch({
      type: "servetel",
      payload: value,
    });

    if (value) {
      dispatch({
        type: "currentAction",
        payload: { anchorEl: null, currentRow: currentRow },
      });
    }
  };

  // close button Click handler

  const handleClose = () => {
    dispatch({ type: "currentAction", payload: { anchorEl: null } });
    dispatch({
      type: "showModal",
      payload: { callModal: false, taksModal: false },
    });
  };

  // Initail reander and table restore table

  useEffect(() => {
    let isRestore = localStorage.getItem("RESTORE_CLIENT_LISTING_SCREEN");

    if (isRestore) {
      restore();
    } else {
      localStorage.removeItem("ClientLeadGlobalSearch");
      getTableData();
    }
  }, []);

  // set visible columns

  useEffect(() => {
    if (pathName === "lead") {
      dispatch({ type: "current", payload: visibileLeadColumns });
    } else {
      dispatch({ type: "current", payload: visibileClientColumns });
    }
  }, [visibileLeadColumns, visibileClientColumns]);

  // Delete client and clear search reRender

  useEffect(() => {
    if (reloadTable) {
      if (!globalSearchedValue) {
        getTableData(CLIENT_LC_STORAGE_DEFAULT);
        apiRef.current.restoreState(CLIENT_LC_STORAGE_DEFAULT);
        onDispatch({ type: "reloadTable", payload: false });

        return;
      }
    }

    if (globalSearchedValue) {
      localStorage.setItem("ClientLeadGlobalSearch", globalSearchedValue);
      getTableData();

      onDispatch({ type: "reloadTable", payload: false });
    }
  }, [reloadTable, globalSearchedValue]);

  // Validity search

  useEffect(() => {
    getTableData();
  }, [validitySearch]);

  // Table column render

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      {pathName === "lead" ? (
        <LeadTable
          apiRef={apiRef}
          rows={rows}
          columns={leadColumnsList}
          count={count}
          handlePagination={handleDataGridChange}
          onFilterChange={handleDataGridChange}
          slots={{ toolbar: CustomToolbar }}
          columnVisibilityModel={currentVisibleColumns}
          handleChangeRowSelection={handleChangeRowSelection}
          onColumnVisibilityModelChange={handleChangeColumns}
          visibileColumns={1}
          pinList={pinList}
        />
      ) : (
        <ClientTable
          apiRef={apiRef}
          rows={rows}
          columns={clientColumnsList}
          count={count}
          handlePagination={handleDataGridChange}
          onFilterChange={handleDataGridChange}
          slots={{ toolbar: CustomToolbar }}
          columnVisibilityModel={currentVisibleColumns}
          handleChangeRowSelection={handleChangeRowSelection}
          onColumnVisibilityModelChange={handleChangeColumns}
          visibileColumns={1}
          pinList={clientPinList}
        />
      )}

      {/* {pathName === "lead" && showCallModal && (
        <TeleCall selectedRow={currentRow} close={handleClose} />
      )} */}

      {showCallModal && (
        <CallPopup
          selectedRow={currentRow}
          callType={"plivo"}
          leadId={leadId}
          close={handleClose}
        />
      )}

      {showTaskModal && (
        <>
          <TaskModal
            closeModel={handleClose}
            leadId={leadId}
            isClient={pathName === "lead" ? false : true}
          />
        </>
      )}

      <Servetel
        isOpen={callType}
        selectedRow={currentRow}
        leadId={leadId}
        onClose={() => handleServetel(false)}
      />

      <ActionMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        onEdit={handleEdit}
        onWhatsapp={handleWhatsapp}
        onCall={handleCall}
        onTask={handleTask}
        onServetel={handleServetel}
        top={top}
        left={left}
      />
    </div>
  );
};

export default Table;
