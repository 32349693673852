import { useState } from "react";

import TaskFilter from "./TaskFilter";
import TabsContainer from "./TabsContainer";
import Tab from "./Tab";
import TaskTable from "./TaskTable";

import "./MyTask.css";

const MyTask = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState("");

  const [filterdata, setFilterData] = useState("");
  const [falg, setFlag] = useState(false);

  const [clearTable, setClearTable] = useState();

  return (
    <div className="task-container">
      <TaskFilter
        className="filter-container"
        activeTab={activeTabName}
        onFilterdata={setFilterData}
        onFlag={setFlag}
        clearTable={setClearTable}
      />
      <div className={"task-tab-container"}>
        <TabsContainer className={"tabs"}>
          <Tab
            num={0}
            name="Overdue"
            activeTab={activeTab}
            onClick={setActiveTab}
          />
          <Tab
            num={1}
            name="Open"
            activeTab={activeTab}
            onClick={setActiveTab}
          />
          <Tab
            num={2}
            name="Completed"
            activeTab={activeTab}
            onClick={setActiveTab}
          />
          <Tab
            num={3}
            name="Cancelled"
            activeTab={activeTab}
            onClick={setActiveTab}
          />
        </TabsContainer>
        <TaskTable
          activeTab={activeTab}
          onActiveTab={setActiveTabName}
          filterdata={filterdata}
          flag={falg}
          onFlag={setFlag}
          clearTable={clearTable}
          onClear={setClearTable}
        />
      </div>
    </div>
  );
};

export default MyTask;
