import React, { useEffect, useState } from "react";

import "./MyTask.css";

import {
  DataGridPro,
  // GridToolbarContainer,
  // GridToolbarFilterButton,
  // GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { toast } from "react-toastify";
import MessageIcon from "@mui/icons-material/Message";

import TaskModal from "../../components/TaskModal/TaskModal";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import TeleCall from "../Call/TeleCall";
import TaskActionMenu from "./TaskActionMenu";
import Comments from "./Comments";
import CallPopup from "../Call/CallPopup";
import Servetel from "../Call/Servetel";

import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";

const TaskTable = ({
  activeTab,
  onActiveTab,
  filterdata,
  flag,
  onFlag,
  clearTable,
  onClear,
}) => {
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editRow, setEditRow] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [showComment, setShowComment] = useState(null);

  const [modifyCurrentdata, setModifyCurrentdata] = useState("");

  const [showCallModal, setShowCallModal] = useState(false);

  const [currentTab, setCurrentTab] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 1,
  });

  const [filter, setFilter] = useState({ items: [] });

  const [role, setRole] = useState("other");

  const [isRole, setIsRole] = useState("");

  const [showServetel, setShowServetel] = useState(false);

  const convertUtcToLocalTime = (utc) => {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleString();
    const inputDate = localTime.split(",");

    const [month, day, year] = inputDate[0].split("/");
    const formattedDate = `${day.padStart(2, "0")}-${month}-${year}`;

    return [formattedDate, inputDate];
  };

  let columns = [
    {
      field: "S.No",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      width: 90,
      renderCell: (params) => {
        const rowId = params.row.id;
        const sortedRowIds = params.api.getSortedRowIds();
        const rowIndex = sortedRowIds.indexOf(rowId) + 1;

        return rowIndex;
      },
    },
    {
      field: "lead_id",
      headerName: "Id",
      headerClassName: "super-app-theme--header",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="id_container">
            <span>{params?.row?.lead_id}</span>
            <span
              data-toggle="tooltip"
              data-placement="top"
              title={params?.row?.isClient ? "Client" : "Lead"}
            >
              <PersonIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return params.row?.task_lead?.name;
      },
    },
    {
      field: "contact_number",
      headerName: "Contact No",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return params.row?.task_lead?.contact_number;
      },
    },
    {
      field: "email_id",
      headerName: "Email Id",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return params.row?.task_lead?.email_id;
      },
    },
    {
      field: "assigned_to",
      headerName: "Assign to",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return params.row?.assigned_to?.firstName;
      },
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return params.row?.assigned_to?.Roles?.Role;
      },
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="action-btn"
            onClick={(e) => handleClick(e, params.row)}
          >
            <MoreHorizIcon />
          </div>
        );
      },
    },
    {
      field: "task_type",
      headerName: "Task Type",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return params.row?.type?.task_type;
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      headerClassName: "super-app-theme--header",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.due_date;
      },
      renderCell: (params) => {
        return convertUtcToLocalTime(params.row?.due_date)[0];
      },
    },
    // {
    //   field: "createdAt",
    //   type: "date",
    //   headerName: "Date",
    //   headerClassName: "super-app-theme--header",
    //   width: 200,
    //   valueFormatter: (params) => {
    //     return params.row?.createdAt;
    //   },
    //   renderCell: (params) => {
    //     return convertUtcToLocalTime(params.row?.createdAt)[0];
    //   },
    // },

    {
      field: "status",
      headerName: "Status",
      filterable: false,
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    // {
    //   field: "due_date",
    //   headerName: "Due Date",
    //   headerClassName: "super-app-theme--header",
    //   width: 200,
    //   type: "date",
    //   valueFormatter: (params) => {
    //     return params.row?.due_date;
    //   },
    //   renderCell: (params) => {
    //     return convertUtcToLocalTime(params.row?.due_date)[0];
    //   },
    // },
    {
      field: "due_time",
      headerName: "Time",
      filterable: false,
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return convertUtcToLocalTime(params.row?.due_date)[1][1];
      },
    },
    {
      field: "due_days",
      headerName: "Due Day",
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        if (params.row?.due_days === "Today") {
          return params.row?.due_days;
        }
        return params.row?.due_days + " days";
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      filterable: false,
      headerClassName: "super-app-theme--header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="comments">
            {/* <span>{params.row?.comments}</span> */}
            <span
              className="comment-icon"
              onClick={(e) => handleCommentClick(e, params.row)}
            >
              <MessageIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
          </div>
        );
      },
    },

    {
      field: "completed_date",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        return params.row?.due_date;
      },
      renderCell: (params) => {
        return convertUtcToLocalTime(params.row?.completed_date)[0];
      },
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   filterable: false,
    //   headerClassName: "super-app-theme--header",
    //   width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         className="action-btn"
    //         onClick={(e) => handleClick(e, params.row)}
    //       >
    //         <MoreHorizIcon />
    //       </div>
    //     );
    //   },
    // },
  ];

  if (tableData.role === "superAdmin" || tableData.role === "manager") {
    const clientIdsToRemove = ["Due Day"];

    const filteredColumns = columns.filter(
      (item) => !clientIdsToRemove.includes(item.headerName)
    );

    columns = filteredColumns;
  } else {
    const clientIdsToRemove = ["Assign to", "Role"];

    const filteredColumns = columns.filter(
      (item) => !clientIdsToRemove.includes(item.headerName)
    );

    columns = filteredColumns;
  }

  if (currentTab !== "completed") {
    const clientIdsToRemove = ["Completed Date"];

    const filteredColumns = columns.filter(
      (item) => !clientIdsToRemove.includes(item.headerName)
    );

    columns = filteredColumns;
  }

  if (tableData.role === "manager") {
    const clientIdsToRemove = ["Role"];

    const filteredColumns = columns.filter(
      (item) => !clientIdsToRemove.includes(item.headerName)
    );

    columns = filteredColumns;
  }

  if (currentTab === "completed") {
    const clientIdsToRemove = ["Due Date", "Due Day", "Action"];

    const filteredColumns = columns.filter(
      (item) => !clientIdsToRemove.includes(item.headerName)
    );

    columns = filteredColumns;
  }
  if (currentTab === "cancelled") {
    const clientIdsToRemove = ["Due Day"];
 
    const filteredColumns = columns.filter(
      (item) => !clientIdsToRemove.includes(item.headerName)
    );
 
    columns = filteredColumns;
  }
  const handleCommentClick = (event, row) => {
    setShowComment(event.currentTarget);
    setEditRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowComment(null);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setEditRow(row);
  };

  const handleModal = () => {
    if (editRow?.status !== "rescheduled") {
      setAnchorEl(null);
      setOpen(true);
    } else {
      toast.warning("Task is already rescheduled", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDeleteTask = async () => {
    const response = await axiosFetch({
      url: `/deleteTask/?taskId=${editRow.id}`,
      method: "delete",
    });

    if (response.status === 200) {
      toast.success(response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      setAnchorEl(null);
      taskData(currentTab);
    }
  };

  const handleWhatsapp = () => {
    const phoneNumber = editRow?.task_lead?.contact_number;
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
    setAnchorEl(null);
  };

  const handleCall = () => {
    setShowCallModal(true);
    setAnchorEl(null);
  };

  const handleChangePaginationModel = async (model) => {
    if (flag) {
      onFlag(false);
      return;
    }

    setPaginationModel(model);
    setPageNo((pre) => {
      return model.page + 1;
    });
  };

  function onFilterChange(filters) {
    setFilter(filters);
  }

  const taskData = async (tab) => {
    const response = await axiosFetch({
      url: `/getTaskDetailsByStatus?userId=${userId}&status=${tab}&page=${pageNo}&perPage=${paginationModel.pageSize}`,
      method: "post",
      requestConfig: filter,
    }).then((res) => res.data);

    setTableData(response);
  };

  const handleReloadEditTask = () => {
    taskData(currentTab);
  };

  useEffect(() => {
    if (clearTable) {
      onClear(false);
      taskData(currentTab);
    }
  }, [clearTable]);

  useEffect(() => {
    const tabMappings = {
      0: "due",
      1: "open",
      2: "completed",
      3:"cancelled"
    };

    const selectedTab = tabMappings[activeTab];

    if (selectedTab) {
      taskData(selectedTab);
      onActiveTab(selectedTab);
      setCurrentTab(selectedTab);
    }
  }, [activeTab, paginationModel, filter]);

  useEffect(() => {
    if (filterdata) {
      setTableData(filterdata);

      // setTimeout(() => {
      //   setTableData(filterdata);
      // }, 500);
    }
  }, [filterdata]);

  useEffect(() => {
    if (editRow) {
      setModifyCurrentdata({
        id: editRow.lead_id,
        contact_number: editRow?.task_lead?.contact_number,
        type: editRow?.isClient ? "Clients/clientDetail" : "lead",
      });
    }
  }, [editRow]);

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("loginDetails"));
    setIsRole(item.Role);
    if (item.Role === "Sales") {
      setRole("sale");
    }
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="table-Container">
      <DataGridPro
        sx={{
          height: "100%",
          width: "100%",
          ".super-app-theme--header": {
            backgroundColor: "#F4F4F4",
          },
          ".MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: "#fff",
          },
          ".MuiDataGrid-row:nth-child(even)": {
            backgroundColor: "#F4F4F4",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
          },
        }}
        columns={columns}
        rows={tableData?.taskList || []}
        rowCount={tableData.totalCount}
        paginationMode="server"
        filterMode="server"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pagination
        pageSizeOptions={[25, 50, 100]}
        page={paginationModel.page}
        onPaginationModelChange={handleChangePaginationModel}
        onFilterModelChange={onFilterChange}
        slots={{
          toolbar: CustomToolbar,
        }}
        localeText={{ noRowsLabel: "No result found !!!" }}
      />

      <Comments
        anchorEl={showComment}
        handleClose={handleClose}
        editRow={editRow}
        taskData={taskData}
        currentTab={currentTab}
      />

      <TaskActionMenu
        anchorEl={anchorEl}
        handleModal={handleModal}
        handleClose={handleClose}
        handleDeleteTask={handleDeleteTask}
        handleWhatsapp={handleWhatsapp}
        handlecall={handleCall}
        onServetel={setShowServetel}
        editRow={editRow}
        handleReloadEditTask={handleReloadEditTask}
      />

      {open && (
        <TaskModal
          closeModel={() => setOpen(false)}
          editRow={editRow}
          reloadTable={handleReloadEditTask}
        />
      )}
      {/* {isRole.split(" ")[0] === "Dietician" ||
      isRole.split(" ")[0] === "Trainer"
        ? showCallModal && (
            <CallPopup
              selectedRow={modifyCurrentdata}
              role={role}
              close={() => setShowCallModal(false)}
              callType="plivo"
            />
          )
        : showCallModal && (
            <TeleCall
              selectedRow={modifyCurrentdata}
              role={role}
              close={() => setShowCallModal(false)}
              callType="plivo"
            />
          )} */}
      {showCallModal && (
        <CallPopup
          selectedRow={modifyCurrentdata}
          role={role}
          close={() => setShowCallModal(false)}
          callType="plivo"
        />
      )}

      <Servetel
        isOpen={showServetel}
        selectedRow={modifyCurrentdata}
        leadId={modifyCurrentdata?.id}
        onClose={() => setShowServetel(false)}
      />
    </div>
  );
};

export default TaskTable;
