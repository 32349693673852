import { Edit, FileCopy } from "@material-ui/icons";
import { Button, IconButton } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
const useViewRecording = () => {
  const[editPopup,setEditPopup] = useState(false)
  const[selectedUrl,setSelectedUrl] = useState()

  const handleEdit = async(url)=> {
      setEditPopup(true)
      setSelectedUrl(url)
  }

  const handleCopy = (url) => {
    if (url) {
      navigator.clipboard.writeText(url).then(() => {
        toast.success("URL Copied",{ position: toast.POSITION.TOP_CENTER })
      }).catch((err) => {
        toast.success("Failed to copy URL",{ position: toast.POSITION.TOP_CENTER })

      });
    }
  };


  const columnslist = [
    {
      field: "no",
      headerName: "S No",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const rowId = params.row.id;
        const sortedRowIds = params.api.getSortedRowIds();
        const rowIndex = sortedRowIds.indexOf(rowId) + 1;

        return rowIndex;
      },
    },
    {
      field: "url",
      headerName: "URL",
      width: 450,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        console.log(params);
        return params.row?.zoom_url;
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            
            {/* <IconButton onClick={() => handleEdit(params.row?.zoom_url)}>
              <Edit />
            </IconButton> */}
    
          
            <IconButton >
              <FileCopy  onClick={() => handleCopy(params.row?.zoom_url)}/>
            </IconButton>
          </div>
        );
      },
    },

  ];
  
  return {columnslist,editPopup,setEditPopup,selectedUrl,setSelectedUrl}
};

export default useViewRecording;
